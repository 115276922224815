import React,{Fragment} from 'react';
import socialNetworks from '../../data/SocialNetworks/socials'

const ContactInfo = ({address}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address>
            </div>
           
        </Fragment>
    );
};

export default ContactInfo;