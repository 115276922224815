import React,{Fragment} from 'react';
import Header from "../components/Header";
import ContactPage from "../templates/Contact";
import PageHeader from '../components/PageHeader/index'
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import {ToastProvider} from 'react-toast-notifications'



const PageContact = () => {
    return (
        <ToastProvider placement="bottom-left" autoDismiss='true'>
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/contact-buena.jpg')}
                title="CONTACTO"
                content=""
            />
            <ContactPage/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
        </ToastProvider>
    );
};

export default PageContact;