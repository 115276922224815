import React from 'react';
import From from "./From";
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Contáctanos, queremos saber de ti.</h3>
                                <From/>
                            </div>
                        </div>

                        <div className="col-lg-4" style={{backgroundColor:'#4c4f51'}}>
                            <div className="contact-information contact-method" style={{backgroundColor:'#4c4f51'}}>
                                <div className="contact-info-con" style={{backgroundColor:'#4c4f51'}}>
                                    <h3>Información de contacto</h3>
                                    <ContactInfo
                                        address="Calle 60 #427 entre 69 y 71, Colonia Mil Piedras. CP 97298.
                                        Mérida, Yuc. México. <br/>999 442 2060 <br/>direccion@bamxmerida.org.mx"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;