import React, {useState} from 'react';
import FormInput from "../../components/UI/Input";
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

const From = () => {
    const { addToast } = useToasts()
const [inputs, setInputs] = useState({})

const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    console.log(inputs)
  }

    const onSubmit = (e) => {
        e.preventDefault()

        axios.post("/.netlify/functions/sendgrid-contact", {
            inputs
        })
        .then((res) =>  addToast('Tu correo ha sido enviado exitosamente.', { appearance: 'success' }))
        .catch(e => {
            console.log(e)})
    }

    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            }}
                            name={'name'}
                            placeholder={'Nombre Completo'}
                        />
                    </div>

                    
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'email_address'}
                            placeholder={'Correo Electrónico'}
                        />
                    </div>


                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}

                            onChange = {e =>  {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'message'}
                            placeholder={'Mensaje'}
                        />

                        <FormInput
                            tag={'button'}
                            classes={'btn-outline'}
                            style={{color:'#288d36'}}
                          
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;